import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Spacer } from 'ascential-horizon'
import Layout from '../Layout'
import getThemeConfig from '../providers/config/themeConfig'
import renderContent from '../utils/componentRenderer'
import Hero from '../components/Hero'
import { useIdentity } from '../providers/identityContext'
import Billboard from '../components/Billboard'
import EmbedIframe from '../components/EmbedIframe'

const TemplateHomePage = ({ data, pageContext, location }) => {
  const {
    header,
    headerAuth,
    home: {
      hero,
      announcement,
      content,
      banner,
      billboard,
      footer,
      pageEmbedIframe,
    },
    footerNav,
  } = data

  const {
    fullWidthIframe,
    footer: { type },
    header: { variation },
  } = getThemeConfig()

  const { isAuthorized, loggedIn, login, logout, user } = useIdentity()

  const getHeaderProps = () => {
    if (user?.loading) {
      return <></>
    }
    const headerProps =
      variation === 'nav'
        ? {
            ...(loggedIn && headerAuth ? headerAuth : header),
          }
        : {
            ...data.home.header,
          }
    return headerProps
  }

  const footerProps = type === 'menu' ? footerNav : footer

  const layoutProps = {
    announcement,
    header: {
      ...getHeaderProps(),
      banner,
    },
    footer: footerProps,
    slug: location && location.pathname,
    pageType: location && location.pathname,
    pageContext,
    location,
  }

  const userDetails = {
    actions: { login, logout },
    isAuthorized,
    location,
    user,
  }

  const hasBillboard = !!billboard && loggedIn && !isAuthorized

  return (
    <Layout {...layoutProps}>
      <Hero {...hero} />
      <Spacer>{renderContent(content, userDetails)}</Spacer>
      {fullWidthIframe && pageEmbedIframe && (
        <EmbedIframe pageEmbedIframe={pageEmbedIframe} />
      )}
      {hasBillboard && <Billboard {...billboard} />}
    </Layout>
  )
}

TemplateHomePage.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hostname: PropTypes.string,
  }).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export const templateHomePageQuery = graphql`
  query($rootId: String!) {
    header: contentfulNav(instanceName: { eq: "Main Menu" }) {
      ...navQuery
    }
    headerAuth: contentfulNav(instanceName: { eq: "Main Menu Auth" }) {
      ...navQuery
    }
    footerNav: contentfulFooter(instanceName: { eq: "Footer" }) {
      ...footerQuery
    }
    home: contentfulTemplateHomePage(id: { eq: $rootId }) {
      ...header
      hero {
        ...heroQuery
      }
      footer {
        ...footerQuery
      }
      announcement {
        ...announcementQuery
      }
      banner {
        ...bannerQuery
      }
      billboard {
        ...billboardQuery
      }
      pageEmbedIframe {
        ...embedQuery
      }
      content {
        __typename
        ... on Node {
          ...multipleComponents
        }
      }
    }
  }
`

export default TemplateHomePage
